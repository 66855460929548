import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Header = styled.h1`
    color: #c33dd4;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
`;

const PostContainer = styled.div`
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
`;

const Tags = ({
    pageContext,
    data: {
        allMarkdownRemark: { nodes }
    }
}) => {
    const { tag } = pageContext;
    return (
        <Layout>
            <SEO title={`Posts tagged ${tag} - Unicorn.computer`} />
            <Header>
                {tag}
                <span role="img" aria-label="tag">
                    🏷
                </span>
            </Header>
            {nodes.map(({ frontmatter, excerpt }) => {
                const { title, path, date } = frontmatter;
                return (
                    <PostContainer key={path}>
                        <Link className="plain-link" to={path}>
                            <h2>{title}</h2>
                        </Link>
                        <h4>{date}</h4>
                        <p>
                            {excerpt}{" "}
                            <Link to={path}>
                                read more{" "}
                                <span aria-label="running person" role="img">
                                    🏃‍♀️
                                </span>
                            </Link>
                        </p>
                    </PostContainer>
                );
            })}
            <Link to="/tags">All tags</Link>
        </Layout>
    );
};

export default Tags;

export const pageQuery = graphql`
    query($tag: String) {
        allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            nodes {
                excerpt(pruneLength: 700)
                frontmatter {
                    title
                    path
                    date
                    featuredImage {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
